import './jquery-global';
import './styles.scss';
import nodeMarquee from 'node-marquee';


(function ($) {

  $(function () {

    'use strict';

    // DOM ready, take it away

    // MOBILE MENU submenu
    $('<div class="subarrow"><i class="fa-regular fa-chevron-down"></i></div>').insertAfter('.mobile-menu li.menu-item-has-children > a');
    $('.mobile-menu .subarrow').on('click', function () {
      if ($(this).parent().hasClass('active')) {
        $(this).parent().removeClass('active');
      } else {
        $(this).parent().addClass('active');
      }
    });

    nodeMarquee({
      parent: '#banner-marquee',
      pauseOnHover: 'true'
    });

    nodeMarquee({
      parent: '#news-marquee',
      pauseOnHover: 'true'
    });


    // Slick
    $('.partners').slick({
      dots: false,
      infinite: true,
      speed: 300,
      mobileFirst: true,
      slidesToShow: 2,
      arrows: false,
      slidesToScroll: 1,
      swipeToSlide: true,
      autoplay: true,
      autoplaySpeed: 3000,
      pauseOnHover: false,
      responsive: [
        {
          breakpoint: 1600,
          settings: {
            slidesToShow: 6,
          }
        },
        {
          breakpoint: 1350,
          settings: {
            slidesToShow: 5,
          }
        },
        {
          breakpoint: 650,
          settings: {
            slidesToShow: 4,
          }
        },
        {
          breakpoint: 500,
          settings: {
            slidesToShow: 3,
          }
        },
        // You can unslick at a given breakpoint now by adding:
        // settings: "unslick"
        // instead of a settings object
      ]
    });

    // Fancybox
    $('.wp-block-gallery .wp-block-image a').attr('data-fancybox', 'gallery');


    // TOGGLE FOR LINEUP TABS
    // ======================================

    $('.lineuptab').on('click', function () {
      var $count = $(this).data("count");

      $('.lineuptab').removeClass('active');
      $(this).addClass('active');

      $('.lineupcontent').removeClass('active');
      $('.lineupcontent-' + $count).addClass('active');

    });

    $('.lineuptab-0, .lineupcontent-0').addClass('active');


    // MOBILE TOGGLE
    // ===============================================

    $('#hamburger').on('click', function () {
      if ($('.menu').hasClass('active')) {
        $('.menu, .page-overlay, .btn-hamburger, nav').removeClass('active');
        $('body').removeClass('overflow-hidden');
      } else {
        $('.menu, .page-overlay, .btn-hamburger, nav').addClass('active');
        $('body').addClass('overflow-hidden');
      }
    });
    $('.page-overlay').on('click', function () {
      $('.menu, .page-overlay, .btn-hamburger').removeClass('active');
      $('body').removeClass('overflow-hidden');
    });

    $('.menu ul li a, .header').on('click', function () {
      $('body').removeClass('overflow-hidden');
    });


    // FAQ
    // ===============================================
    $('.faq-open').on('click', function () {
      if ($(this).parent().parent().hasClass('active')) {
        $(this).parent().parent().removeClass('active');
      } else {
        $(this).parent().parent().addClass('active');
      }
    });

    $('.faq-category').on('click', function() {
      // Controleer of de huidige knop al actief is
      var isActive = $(this).hasClass('active');
      
      // Verwijder eerst de 'active' klasse van alle knoppen
      $('.faq-category').removeClass('active');
      
      // Als de knop al actief was, toon alle .faq-block elementen
      if (isActive) {
          $('.faq-block').show();
      } else {
          // Voeg de 'active' klasse toe aan de huidige knop
          $(this).addClass('active');
          
          // Haal de waarde op van het data-faq attribuut van de geklikte knop
          var selectedFaq = $(this).data('faq');
          
          // Als er een knop is geselecteerd, toon het .faq-block element met overeenkomende data-faq waarde
          if (selectedFaq) {
              $('.faq-block').hide();
              $('.faq-block[data-faq="' + selectedFaq + '"]').show();
          } else {
              // Als er geen knop is geselecteerd, toon alle .faq-block elementen
              $('.faq-block').show();
          }
      }
    });

    // HEADER SCROLLED
    // ===============================================
    $(window).scroll(function () {
      var scroll = $(window).scrollTop();
      if (scroll >= 150) {
        $("nav, body").addClass("scrolled");
      }

      if (scroll < 50) {
        $("nav, body").removeClass("scrolled");
      }
    });

    // AOS
    // ===============================================
    AOS.init({
      duration: 400,
      offset: 0, // offset (in px) from the original trigger point
      once: true, // whether animation should happen only once - while scrolling down
      anchorPlacement: 'top-bottom', // define where the AOS animations will be triggered
      });
    window.addEventListener('load', AOS.refresh);
  });

})(jQuery);