import $ from "jquery";
globalThis.jQuery = $;

import 'slick-carousel';
import 'slick-carousel/slick/slick.scss';
import 'slick-carousel/slick/slick.min.js';

// FontAWesome
import '@fortawesome/fontawesome-pro/js/fontawesome.min.js';
import '@fortawesome/fontawesome-pro/js/brands.min.js';
import '@fortawesome/fontawesome-pro/js/solid.min.js';
import '@fortawesome/fontawesome-pro/js/regular.min.js';



import '@fortawesome/fontawesome-pro/css/regular.min.css';
import '@fortawesome/fontawesome-pro/css/solid.min.css';
import '@fortawesome/fontawesome-pro/css/brands.min.css';

// import { Fancybox } from "@fancyapps/ui";
// import "@fancyapps/ui/dist/fancybox/fancybox.css";
